.footer-contianer {
  height: 4.02vh;
  width: 100vw;
  position: fixed;
  bottom: 0px;
  background-color: white;
  z-index: 100;
}
.footer-policy-txt {
  padding-left: 19px;
  font-weight: normal;
  color: black;
  font-style: normal;
  font-weight: normal;
}

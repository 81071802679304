.register-container.ui.bottom.attached.segment.active.tab {
  min-height: 87vh;
  background-color: var(--background-color);
  font-weight: bolder;
}
.register-container .ui.menu .active.item {
  background-color: var(--background-color) !important;
}
.register-container .ui.tabular.menu .active.item {
  color: var(--font-color) !important;
}
.register-container .ui.segment {
  border: none !important;
}

.register-container .ui.tabular.menu {
  border: none;
  min-height: 34px;
}
.ui.menu {
  min-height: 0px !important;
}
.register-container .ui.tabular.menu .item {
  border: none;
  width: 100px;
  font-size: 16px;
  font-weight: bolder;
  color: var(--font-color);
}
.register-container .ui.form .field {
  margin: 0 0 0rem;
}
.register-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
}
.logo-margin {
  margin-top: 20px;
  margin-bottom: 30px;
}
.resgister-form-inner-item > * {
  width: 100%;
}
.logo-container {
  height: 110px;
}
.acctmaking-form-container .form1-item {
  width: 95% !important;
  margin-bottom: 15px !important;
}
.acctmaking-form-container .ui.form {
  width: 80% !important;
  margin: 0% 10% 0% 11%;
}
.acctmaking-container .form-container .obligation-input {
  background-color: white !important;
  border: 1px solid #bdbdbd !important;
}
.acctmaking-container {
  width: 100%;
  height: 98%;
  overflow: auto;
}
.acctmaking-form-container .form-container {
  width: 100%;
  background-color: white;
  height: 440px;
}
/* Gateway koppelen and annsluiten */
.acctmaking-form-container {
  width: 325px;
}
.gateway-koppelen-form-container {
  width: 32.5%;
}
.gateway-koppelen-form-container .form-container {
  background-color: white;
  width: 325px;
  min-height: 305px;
}
.gateway-container {
  width: 100%;
}
.gateway-container .form1-item {
  width: 248px !important;
  height: 39px !important;
}
.account-container {
  width: 100%;
}
.gateway-image-container .ui.button {
  padding: 1rem 1rem 1rem;
}
.gateway-image-container {
  margin-left: 40%;
  width: 100%;
}
.gateway-aansluiten-info {
  width: 620px;
  height: 250px;
}
.gateway-aansluiten-info > div {
  margin-bottom: 5%;
  font-size: 16px;
}
.instruction {
  font-size: 16px;
}
.txt-container {
  width: 40%;
}
.txt-container > * {
  margin-left: 22%;
}
.mail-verified-container > *:nth-child(2) {
  margin-bottom: 1.5%;
}
.mail-verified-container > *:first-child {
  margin-bottom: 5%;
}
.mail-verified-container {
  background-color: var(--background-color);
  height: 87%;
}
/* padding and margin classes */
.py-bottom {
  padding-bottom: 3rem;
}
.py-top {
  padding-top: 5vh;
}
.px-left-gatewaytxt {
  padding-left: 5%;
}
.px-left {
  margin-left: 15px;
}
.succesful-py-top {
  padding-top: 50px;
}
.m-bottom {
  margin-bottom: 15px !important;
}
.m-top {
  margin-top: 10px !important;
}
.m-y-top {
  margin-top: 60px !important;
}
.slimme-meter-container {
  width: 343px;
  height: 343px;
  margin-right: 50px;
}
.gateway-m-top {
  margin-top: 20px;
}
/* Add a green text color and a checkmark when the password requirements are right */
.valid {
  color: green;
}
.valid:after {
  position: relative;
  left: 0%;
  top: 15%;
  bottom: 0%;
  content: '✔';
}
/* Add a red text color and an "x" when the password requirements are wrong */
.invalid {
  color: red;
}
.invalid:after {
  position: relative;
  left: 0%;
  top: 15%;
  bottom: 0%;
  content: '✖';
}
.help {
  position: relative;
  /* left: 100%; */
  bottom: -15%;
  font-size: 20px;
}
/* loading symbol rotates */
.rotate {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/* Regarding tooltip */
.tooltip {
  position: relative;
  width: 10%;
}

.tooltip .tooltiptext {
  visibility: hideen;
  width: 20rem;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  font-size: 1rem;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: -250%;
  left: 150%;
  margin-left: -5px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.register-container .ui.tabular.menu > .disabled.item {
  opacity: 0.45 !important;
}
#tab1,
#tab2,
#tab3,
#Date {
  display: inline-block;
  pointer-events: none;
  padding: 4px;
  text-align: center;
  width: 100px;
  margin-top: 11px;
  height: 25px;
}
.text-align-right {
  text-align: right;
}
.password-container {
  position: relative;
  margin-top: 10px;
}
.flex2-row {
  position: absolute;
  left: 98%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.gateway-koppelen {
  font-weight: 700;
  font-size: 24px;
  font-family: Nunito Sans;
  line-height: 32.47px;
}
.instruction-container {
  width: 325px;
}
.register-container h5 {
  font-size: 24px;
  margin-left: 0px !important;
  font-family: 'Nunito Sans';
}
h3 {
  margin: 0;
}
.register-container h4 {
  color: var(--uielements-color);
  font-size: 14px;
  font-weight: 600 !important;
  font-family: 'Nunito Sans';
}
.register-container .resgister-form-inner-item strong {
  color: var(--uielements-color);
  font-size: 14px;
  font-weight: 600 !important;
  font-family: 'Nunito Sans';
}

.customer-detail-container {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}
.customer-detail-container .ui.bottom.attached.segment.active.tab > div {
  height: 100%;
}
.customer-detail-container .form1-item {
  background-color: var(--background-color);
  border: none;
  width: 55%;
  height: 2rem;
  border-radius: 5px;
  margin-bottom: 0.7rem;
  font-size: 1.2rem;
}
.customer-detail-container .ui.form input {
  background-color: var(--background-color) !important;
  border: none !important;
  padding-left: 5% !important;
  font-family: 'Nunito Sans' !important;
  font-size: 14px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  width: 380px;
  height: 31px;
}
.customer-detail-container h3 {
  font-size: 18px;
  color: #4d4d4d !important;
  font-weight: 600;
}
.customer-detail-form-container {
  width: 683px;
  // height: 90%;
  background-color: white;
  overflow: auto;
  margin-left: 15px;
}
.customer-detail-form-container .ui.form {
  font-size: 1.2rem;
}
.customer-detail-form-container > *:first-child {
  margin: 20px 0px 10px 0px;
  padding-left: 5%;
}
.customer-detail-form-container > hr {
  width: 94%;
  margin: 0px 3%;
  border-top: 2px solid #8d99ae;
}
.customer-details {
  width: 100%;
  margin-top: 3%;
  padding-right: 15px;
}
.customer-detail-form-container > .ui.form {
  width: 100% !important;
  margin: 0 !important;
}

.customer-detail-form-container .ui.form .field {
  margin: 0 5px !important;
}
.customer-detail-names {
  width: 35%;
  margin-left: 20px;
  margin-bottom: 0.7rem;
}
.customer-details > .flex1-row > .field {
  width: 65% !important;
}

.customer-detail-inputs {
  width: 60%;
}
.streethous {
  width: 65%;
}
.streethous > *:nth-child(1) {
  width: 75%;
  margin-right: 5% !important;
}

.streethous > *:nth-child(2) {
  width: 20%;
}
.postcode-city {
  width: 65%;
}
.postcode-city > *:nth-child(1) {
  width: 35%;
  margin-right: 5% !important;
}
.postcode-city > *:nth-child(2) {
  width: 60%;
}
.user-logo {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.5rem;
}
.ui.form .customer-details .obligation-input {
  background-color: #cccccc !important;
}
.user-name {
  font-size: 1.5rem;
}
.m-py {
  margin-top: 10px;
}
.m-py-bottom {
  margin-bottom: 1rem;
}
.customer-detail-m-py {
  margin-top: 1%;
}
.m-px {
  margin-left: 15%;
}
.bell-logo {
  margin-right: 1.5rem;
}
.required-field::after {
  content: '*';
  color: red;
  font-size: 2vh;
}
.customer-detail-form-container Button {
  margin-top: 10px;
}
.customer-detail-form-container .ui.selection.dropdown {
  min-height: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 400;
  color: var(--font-color);
}

#root {
  height: 100vh;
  /* position:relative!important; */
}

:root {
  --header-color: #07667c;
  --background-color: #e6f3f8;
  --green-color: #0ead69;
  --yellow-color: #fbbb21;
  --widgettitles-color: #4d4d4d;
  --uielements-color: #00b7d5;
  --font-color: #4d4d4d;
  --place-holder-color: #8f8f8f;
  --grey-color: #cccc;
  --dark-grey-color: rgb(224, 224, 224);
  --lyv-red: #dc7570;
}

.btn-green {
  background: #27ae60 !important;
  font-family: 'Nunito Sans' !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: white !important;
  border-radius: 3px;
  height: 39px;
  margin-bottom: 5px !important;
  width: 248px;
}

.btn-blue {
  background-color: var(--uielements-color);
  border-radius: 5px;
  height: 24px;
  width: 80px;
  color: white;
  border: none;
  margin-top: 20px;
}

/* Flex alignments */
.flex1-column {
  display: flex;
  flex-direction: column;
}

.flex1-row {
  display: flex;
  flex-direction: row;
}

.flex1-center {
  justify-content: center;
  align-items: center;
}

.flex1-column-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-center-vertical {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-center-h-v {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 280px;
  width: 100%;
}
.space-between {
  justify-content: space-between;
}
.flex1-end {
  justify-content: flex-end;
}
.flex1-start {
  justify-content: flex-start;
}
.admin-portal {
  color: white;
  font-family: 'Nunito Sans';
  font-weight: 900;
  margin-left: 20px;
  font-size: large;
}
/* For centering a text */
.text-center {
  margin: auto;
  text-align: center;
}

.text-left {
  margin-left: -10px;
}
.padding-zero {
  padding: 0px 0px 0px 5px !important;
}
.logo-size {
  width: 79.57px;
  height: 80px;
}

.form-inner-item {
  box-sizing: border-box;
  width: 100%;
  margin: 2% 0%;
}

.ui.form {
  width: 70% !important;
  margin: 0% 15%;
  font-size: 0.75rem;
}

.form1-item {
  width: 100% !important;
  margin-bottom: 15px !important;
}
input {
  background-color: var(--background-color);
  border: none;
  padding-left: 5%;
  font-family: 'Nunito Sans';
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 3px;
}
.ui.form input {
  background-color: var(--background-color) !important;
  border: none !important;
  padding-left: 0.7rem !important;
  font-family: 'Nunito Sans' !important;
  font-size: 14px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  width: 248px;
  height: 39px;
}
.ui.checkbox label:before {
  background: var(--background-color) !important;
  border-radius: 3px !important;
  height: 16px;
  width: 16px;
}
.add-device-btn {
  background: var(--uielements-color) !important;
  color: #ffff !important;
  padding: 0.5rem 0.8rem !important;
}
.title {
  font-size: '18px';
  font-weight: 'bold';
  color: '#4d4d4d';
}
.ui.dropdown > .text {
  color: black !important;
}

.error-msg {
  color: red;
  font-size: 1rem;
}

/* Placeholder text for input text */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--font-color) !important;
  font-size: 14px !important;
  font-weight: 400;
  font-weight: Nunito Sans;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--font-color) !important;
  font-size: 14px !important;
  font-weight: 400;
  font-weight: Nunito Sans;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--font-color) !important;
  font-size: 14px !important;
  font-weight: 400;
  font-weight: Nunito Sans;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: var(--font-color) !important;
  font-size: 14px !important;
  font-weight: 400;
  font-weight: Nunito Sans;
}
.ui .item .simple .dropdown {
  color: white;
  font-size: 2rem;
}

.ui.dropdown > .text {
  color: white !important;
}
input[type='date']::-webkit-calendar-picker-indicator {
  background: url(https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-16.png) center/7%
    no-repeat;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  margin-top: 15px;
  width: auto;
}
.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1;
}
.ui.input:not(.disabled) input[disabled] {
  opacity: 1;
  background-color: grey !important;
}
.customer-detail-logo-size {
  width: 30px;
  height: 30px;
  margin-top: 0.5rem;
}

.customer-details-header {
  margin-left: 0.5rem;
  margin-block-start: 1rem !important;
}

.serial {
  background-color: #4d4d4d;
}
#Date {
  font-size: 14px;
  width: 200px;
}

.expected {
  color: #8f8f8f;
  &.normal-tile {
    width: 44%;
  }
  &.modified-tile {
    width: 76%;
  }
}
.ui.tabular.menu .active.item {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
}
.m-t-medium {
  margin-top: 10px;
}
.m-t-large {
  margin-top: 30px;
}
.m-b-medium {
  margin-bottom: 10px;
}
.m-r-medium {
  margin-right: 10px;
}
.line-height {
  line-height: 5;
}
.p-left-xs {
  padding-left: 5px;
}
.m-l-large {
  margin-left: 20px;
}
.m-l-small {
  margin-left: 5px;
}
.m-l-medium {
  margin-left: 10px;
}
.p-t-zero {
  padding-top: 0px !important;
}
.p-b-zero {
  padding-bottom: 0px !important;
}
.heading {
  color: #4d4d4d;
  &.big {
    font-size: 18px;
  }
  &.medium {
    font-size: 16px;
  }
  &.small {
    font-size: 14px;
  }
}

.p-t-medium {
  padding-top: 1rem;
}
.m-t-small {
  margin-top: 5px;
}
.p-medium {
  padding: 10px;
}
.full-width {
  width: 100%;
}
.width-small {
  width: 20%;
}
.width-medium {
  width: 60%;
}
.width-medium-pixel {
  width: 120px;
}
.width-standard {
  width: 100px;
}
.font-medium {
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;
}
.right-arrow-icon {
  height: 34px;
  width: 34px;
}
.ui.dropdown > .text::first-letter {
  text-transform: capitalize;
}
.ui.selection.dropdown .menu > .item::first-letter {
  text-transform: capitalize;
}
#tab4 {
  background-color: white !important;
}
.footer-contianer {
  height: 4.02vh;
  width: 100vw;
  position: fixed;
  bottom: 0px;
  background-color: white;
  z-index: 100;
}
.footer-policy-txt {
  padding-left: 19px;
  font-weight: normal;
  color: black;
  font-style: normal;
  font-weight: normal;
}
.delete-btn {
  background-color: var(--lyv-red) !important;
  color: white !important;
}
.lyv-blue-btn {
  background-color: var(--uielements-color) !important;
  color: white !important;
}
.textAlignCenter {
  text-align: center !important;
}
.textAlignLeft {
  text-align: left !important;
}
.large-field {
  width: 70%;
}
.short-field {
  width: 25%;
}
.m-l-community {
  margin-left: 10px !important;
}
.scroll {
  overflow-y: scroll;
}

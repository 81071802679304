#newUser-active.active {
  font-weight: bold !important;
  background-color: white !important;
}

#users-active.active {
  font-weight: bold !important;
  background-color: white !important;
}

.fit-content {
  width: fit-content !important;
}


.users-container {
  height: 95% !important;
  max-width: 14500px;
  width: 95%;
  margin: auto;
  background-color: white;
  overflow: hidden;
}

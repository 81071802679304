.gas-graph-positioning {
  position: relative;
  // height: 24.36vh;
}
.gas-symbol {
  position: absolute !important;
  height: 2rem;
  width: 100%;
  left: 0%;
  top: 26%;
  &.modified {
    left: -27.5%;
  }
}
.m-x-left {
  margin-left: 10rem;
  margin-top: 1rem;
}
.m-x-left-expected {
  margin-left: 5rem;
  margin-top: 1rem;
}
.m-top-my {
  margin-top: 20px;
}
/* Media query goes here */
@media screen and (max-width: 1200px) {
  .m-x-left {
    margin-left: 5rem;
  }
  .m-x-left-expected {
    margin-left: 2rem;
  }
}

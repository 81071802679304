@import '../../../sass/mixins';

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 27px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9d9d9c;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before,
.slider-pending-status:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3cd2a0;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3cd2a0;
}

input:checked + .slider:before,
input:checked + .slider-pending-status:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round,
.slider-pending-status.round {
  border-radius: 34px;
}

.slider.round:before,
.slider-pending-status.round:before {
  border-radius: 50%;
}

.slider:after,
.slider-pending-status:after {
  content: 'X';
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 75%;
  font-size: 25px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after,
input:checked + .slider-pending-status:after {
  content: '✓';
  top: 48%;
  left: 20%;
}
.slider-pending-status {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3cd2a0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider-pending-status {
  background-color: #9d9d9c;
}

input:focus + .slider-pending-status {
  box-shadow: 0 0 1px #9d9d9c;
}
.smart-meter {
  height: 100px;
  width: 100px;
}

.smart-plug .ui.left.attached.button {
  padding: 5px 20px;
}

.smart-plug .ui.left.attached.button {
  padding: 5px 20px;
}

.ui.left.attached.button {
  background: var(--uielements-color);
  color: white;
  padding: 6px 20px;
  border-radius: 0px;
}

.ui.right.attached.button {
  background: white;
  color: var(--uielements-color);
  padding: 5px 20px;
  border: 1px solid var(--uielements-color);
  border-radius: 0px;
}

.smart-plug .ui.button,
.boiler-container .ui.button {
  background: white;
  color: var(--uielements-color);
  height: 20px;
  width: 50px;
  padding: 2.5px;
  border: 1px solid var(--uielements-color);
  overflow-y: auto;
}

#reset {
  font-weight: 600;
  margin-left: 10px;
  font-size: 11px;
}

.boiler-temperature {
  color: #dc7570;
  font-weight: 600;
  font-size: 14px;
}

.boiler-size {
  height: 85px;
  width: 100px;
}
.boiler-text-container {
  height: 100px;
  margin-left: 50px;
}
.on-off {
  color: #8f8f8f;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0.8rem;
}
.m-x-l {
  @include marginLeft(30px);
}

.m-y-t {
  @include marginTop(5px);
}

.detail-txt {
  @include marginRight(5px);
  font-weight: 700;
  font-size: 15px;
  color: var(--uielements-color) !important;
}

.boiler-font-size {
  font-size: 13px;
}

.electric-auto-size {
  height: 100px;
  width: 100px;
}

.add-device {
  position: absolute;
  top: -3.3vh;
  left: 75%;
  color: white !important;
  background: var(--uielements-color) !important;
  border-radius: 5px !important;
  width: 150px;
  height: 24px;
  font-family: 'Nunito Sans' !important;
  border: none;
  margin-bottom: 20px !important;
}

#modal {
  width: 1200px !important;
}

.device-gird-size {
  width: 90%;
}

.device-m-x-l {
  margin-left: 5%;
}

.active-device {
  margin-left: 2.5% !important;
  height: 97%;
  width: 95%;
}

.search-box-container {
  width: 85%;
}

.asset-details {
  font-size: 11px;
  font-weight: 600;
  color: #000000;
  border: none;
}
.vertical-gap {
  line-height: 2.5;
}
.reading-info {
  font-size: 11px;
  font-weight: 600;
  color: #8d8d8d;
}

.asset-container {
  height: 190px;
}
.detail-container {
  height: auto;
  background-color: white;
}

.device-selected-detail {
  min-height: 150px;
  width: 100%;
  border: 1px solid #c4c4c4;
  padding: 10px;
}

.selected-detail {
  margin: 5px 20px 0px 20px;
}

.device-container-column1 {
  width: 150px;
}

.device-container-column3 {
  width: 150px;
}

.blue-arrow-size {
  height: 15px;
  width: 20px;
}

#search-input {
  background: var(--background-color) !important;
  border-radius: 5px;
  border: none;
  margin-left: 10px;
  font-family: 'Nunito Sans';
  margin-top: 10px;
  @include marginBottom(10px);
}

.search-box {
  @include marginBottom(10px);
}

.device-selected-detail .ui.selection.dropdown {
  min-height: 30px;
}

.device-container-column2 .ui.input > input {
  background-color: #dedede;
  width: 100px !important;
  height: 24px;
}

.device-icon-size {
  height: 50px;
  width: 50px;
}
.gas-meter {
  height: 40px;
  width: 40px;
}
.delete-device {
  height: 20px;
  width: 20px;
}

#device {
  margin-top: 20px;
}

i.icon.close:before {
  color: black;
}

.column1 {
  width: 60px;
}

.column2 {
  width: 250px;
}

.column3 {
  width: 120px;
}

.column4 {
  width: 70px;
}

.smart-column1 {
  width: 30%;
}

.smart-column2 {
  width: 70%;
}

.name {
  margin: 0px !important;
}
.selected-detail .ui.fluid.dropdown {
  width: 60%;
}

.active-device-m-y-t-b {
  margin: 10px 0px 10px 0px;
}

.description {
  font-size: 16px;
  font-weight: 600;
  
}

h5 {
  font-size: 14px;
  font-family: 'Nunito-sans';
  font-weight: 400;
}

.device-selected-detail {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

.deleteunavailable {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

.ui.modal > .close {
  background: var(--background-color) !important;
  top: 0.5rem !important;
  right: 0.8rem !important;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@media screen and (max-width: 1200px) {
  .add-device {
    top: -3.5vh;
    left: 68%;
  }
}

@mixin marginLeft($marginleft) {
  margin-left: $marginleft;
}
@mixin marginRight($marginright) {
  margin-right: $marginright;
}
@mixin marginTop($margintop) {
  margin-top: $margintop;
}
@mixin marginTopBottom($margintop, $marginbottom) {
  margin-top: $margintop;
  margin-bottom: $marginbottom;
}
@mixin marginBottom($marginbottom) {
  margin-bottom: $marginbottom;
}

.data-table {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;

    .data-table__inner {
        width: 80%;
        padding: 15px;
        background-color: white;
    }
}

.table {

    width: 100%;
    border-collapse: collapse;

    th {
        color: black;
        font-weight: bolder;
        border-bottom: 1px solid black;
        padding: 5px;
        margin-bottom: 45px;
        text-align: left;
    }
}
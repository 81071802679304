.battery-discharged {
  color: var(--header-color);
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  font-size: 16px;
  font-weight: 400;
}
.battery-filled-value > *:first-child {
  color: var(--header-color) !important;
  margin: 5% 5% 0 5%;
}
.battery-filled-value > *:nth-child(2) {
  color: #8f8f8f;
  margin: 5% 5% 0 0;
}
h2 {
  margin-bottom: 40px;
}
.monitor-graph-container {
  height: 270px;
}

.battery-arrow-icon {
  height: 34px;
  width: 34px;
}
.battery-outer {
  position: relative;
  display: inline-block;
}
.battery-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.battery-size {
  width: 250px;
}

.tableInput {
  margin: 0px;
  padding: 0px;
  font-weight: bolder;
  width: 7rem;
}

.dirty {
  background-color: rgb(255, 227, 152);
}

.editableElement:hover {
  cursor: pointer;
}
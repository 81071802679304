.electric-auto {
  height: 100%;
  width: 100%;
}
.electric-auto .car-icon {
  width: 250px;
}
.electric-auto-filled-value > div {
  color: var(--header-color);
  font-size: 16px;
  font-weight: 900;
}
.electric-auto-detail {
  color: #8f8f8f;
}
.car-outer {
  position: relative;
  display: inline-block;
}
.car-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.car-size {
  height: 100px;
}
.margin-auto {
  margin: auto;
}

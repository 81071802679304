.ui.menu > .item {
  font-weight: bold;
  font-size: 1.5rem;
}

.source-container {
  height: 95%;
  width: 90%;
  margin: auto;
  overflow: hidden;
  
  .ui.secondary.menu > .item.active {
    font-weight: bold !important;
    color: white !important;
    padding: 1em !important;
    background-color: var(--uielements-color) !important;
  }
}

.source-container.dso-source-container {
  display: flex;
  flex-direction: column;

  .dso-header {
    height: 4rem;
  }

  div.ui.segment {
    height: calc(100% - 4rem);

    .dso-container {
      height: 100%;
      overflow-y: scroll !important;
      
      .table > thead > tr > th:nth-child(n+3) {
        text-align: center !important;
      }
    
      td:nth-child(n+3) {
        text-align: center !important;
      }
    }
  }
}

.tableInput {
  margin: 0px;
  padding: 0px;
  font-weight: bolder;
  width: 7rem;
}

.dirty {
  background-color: rgb(255, 227, 152);
}

.editableElement:hover {
  cursor: pointer;
}

.dirty > .ui.selection {
  color: black;
  background-color: rgb(255, 227, 152);
  margin: 0px;
  padding: 3px;
}

.dirty > .ui.selection >.text {
  color: black !important;
}

.dirty > .ui.selection > i.icon::before {
  color: black !important;
}

#taxContainer {
  .table > thead > tr > th:nth-child(n+4) {
    text-align: center !important;
  }

  td:nth-child(n+4) {
    text-align: center !important;
  }
}

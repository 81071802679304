.grid-column-height {
  min-height: 81.5vh;
}
.grid-height-half {
  min-height: 40vh;
  width: 100%;
}
.grid-column-width {
  width: 32.8vw !important;
}
.grid-height-half-middle {
  min-height: 40vh;
  width: 100%;
}
.home-menu-container .ui.grid > .row {
  padding: 1.8rem 0 0 0;
}
#battery,
#solar {
  margin-top: 3.04vh;
}

.grid-size {
  margin-left: 6% !important;
  height: 100%;
  width: 94%;
}
.grid-expanded {
  min-height: 81.5vh;
  width: 64vw;
}
.tile-content {
  height: 190px;
}
.normal-tile {
  width: 100%;
}
.expanded-tile {
  width: 45%;
}

.community-row-detail {
  width: 100%;
  padding: 10px;
  margin: auto;

  .community-row-detail__inner {
    height: 100%;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .community-form {
    height: 100%;
  }
  .community-manager-form > .form {
    width: 100% !important;
    margin: auto !important;
  }

  .community-form > .form {
    width: 100% !important;
    margin: auto !important;
  }
}

.community {
  height: 100%;
  width: 49%;
}

.credit {
  height: 100% !important;
  width: 49%;
}
.community-manager {
  height: 100% !important;
  width: 49%;
}
.community-manager .ui.dropdown > i.icon:before {
  color: var(--font-color);
  font-size: 14px;
}
.community-manager .ui.dropdown > .text,
.ui.dropdown:not(.button) > .default.text {
  position: absolute;
  top: 0.4em !important;
  color: var(--font-color) !important;
  font-weight: normal;
  font-size: 1rem !important;
  font-family: 'Nunito Sans';
}
.community-manager .ui.selection.dropdown {
  padding: 1.3rem !important;
}
.form-title {
  border-bottom: 1px solid black;
  text-align: left;
  font-weight: bolder;
  color: black;
}

.community-form {
  height: 100%;
  padding: 10px;
  width: 100%;
}
.community-manager-details {
  height: 390px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.new-community {
  font-weight: bolder;
}
.form2-item {
  width: 100% !important;
  margin-bottom: 5px !important;
}
.community-container {
  height: 93vh !important;
}
h5 {
  margin: 5px 0px 10px 18px !important;
}
.community-form .ui.form {
  font-size: 1.2rem;
  width: 100% !important;
  margin: 0% 3%;
}
p:first-child {
  margin-top: 5px;
}
.input-details-container {
  width: 100% !important;
}
.community-details-names {
  width: 40%;
}
.community-form h5 {
  margin: 5px 0px 5px 0px !important;
}

.field.form2-item {
  input:disabled.input-details-container {
    background-color: var(--grey-color) !important;
    color: black !important;
    width: 100% !important;
  }
}

.input-details-container > button:disabled {
  background-color: #7fdaeb;
}
.large-field {
  width: 70%;
}
.short-field {
  width: 25%;
}
.m-l-community {
  margin-left: 10px !important;
}

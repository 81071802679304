.asset-detail-container {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .energy-supply-container {
    min-width: 590px;
    min-height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .energy-form-container {
    width: 98%;
    background-color: white;
    .energy-supply-details {
      width: 100%;
      height: 300px;
      margin-top: 5%;
      padding-right: 15px;

      .ui.selection.dropdown {
        height: 26px;
        margin-bottom: 0.7rem;
      }
      .obligation-input {
        background-color: #e0e0e0 !important;
        opacity: 0.9;
      }
    }
    .energy-supply-detail-names {
      width: 50%;
      margin-left: 20px;
      margin-bottom: 0.7rem;
      text-align: left;
    }
    .message {
      width: 100%;
      margin-right: 30px;
      margin-bottom: 0.7rem;
      text-align: left;
      padding: 5px;
    }
    form {
      width: 100% !important;
      margin: 0% 0%;
      font-size: 0.75rem;
    }
    .form-item {
      background-color: var(--background-color);
      border: none;
      width: 40%;
      height: 2rem;
      border-radius: 5px;
      margin-bottom: 0.7rem;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .network-m-t {
    margin-top: 8px;
  }
  .network-m-t-small {
    margin-top: 5px;
  }
  .ui.dropdown > .text,
  .ui.dropdown:not(.button) > .default.text {
    position: absolute;
    top: 0.4em !important;
    color: #4d4d4d !important;
    font-size: 1rem !important;
    font-family: 'Nunito Sans';
  }
  .info {
    font-size: 18px;
    font-weight: 400;
  }
  .ui.form input:not([type]),
  .number-format {
    color: #4d4d4d !important;
    font-weight: 400 !important;
    padding-left: 15px !important;
    height: 26px;
  }
  input.form-item.number-format {
    padding-left: 17px !important;
  }
  .ui.dropdown .menu .active.item {
    background-color: #cccc !important;
  }
  .ui.disabled.dropdown,
  .ui.dropdown .menu > .disabled.item {
    background-color: rgb(224, 224, 224) !important;
    opacity: 0.96;
  }
  /* Regarding tooltip */
  .tooltip {
    position: relative;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 469px;
    height: auto;
    background-color: #ffffff;
    color: #555;
    border-width: 1px;
    border-style: solid;
    border-color: #4d4d4d;
    text-align: left;
    border-radius: 6px;
    font-size: 16px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: -430%;
    left: -1300%;
    margin-left: -5px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
.unit {
  padding-top: 8px;
  opacity: 0.9;
  font-size: 14px;
}
.width-small {
  width: 10px;
}


.home-menu-container {
  height: 89vh;
  width: 100vw;
}
.home-container .ui.attached.tabular.menu {
  height: 46px;
}
.home-menu-container .ui.attached.tabular.menu {
  height: 24px;
}
.home-menu-container .ui.bottom.attached.segment.active.tab {
  background-color: var(--background-color);
  height: 350vh;
}
.home-container .header-logo-positioning {
  margin-right: 20rem;
}
.ui.attached:not(.message) + .ui.attached.segment:not(.top) {
  border-left: none;
  padding-left: 0px;
}
.home-menu-container .ui.grid > .row {
  padding: 1.5rem 0 0 0;
}
.home-container .ui.tabular.menu + .attached:not(.top).segment {
  height: 390%;
}
.home-container {
  background-color: var(--background-color);
  min-height: 158vh;
  overflow: auto;
}
.home-container .ui.bottom.attached.segment {
  padding: 10px 5px 10px 0px !important;
}
.home-container .ui.segment {
  border: 0px !important;
}
.arrow-icon {
  height: 34px;
  width: 34px;
}
.monitor-arrow-up {
  height: 40px;
  width: 45px;
}
.home-container .header-container {
  background-color: var(--header-color) !important;
}
.home-container .ui.tabular.menu .item {
  padding: 0;
  margin-right: 1rem;
}
.home-container .header-container a {
  text-align: center;
  margin-top: 1rem !important;
  margin-right: 0 !important;
  height: 34px;
  width: 150px;
}
.home-container .header-container h4 {
  color: var(--uielements-color) !important;
  font-size: 16px;
  font-weight: 600;
}
#home-active-user,
#reports-active-user,
#vpp-active-user,
#optimalisatie-active-user {
  margin: 1rem 1rem -1rem 1rem !important;
}
.home-menu-container a {
  padding: 0rem 1rem 0rem 1rem !important;
}
#home-active-user {
  padding: 0rem 3.5rem 0rem 3.5rem !important;
}
#reports-active-user,
#optimalisatie-active-user {
  padding: 0rem 1.5rem 0rem 1.5rem !important;
}
.home-menu-container .active.item {
  background-color: var(--background-color) !important;
}
.home-menu-container .ui.tabular.menu {
  border: none;
  height: 24px;
}
.home-menu-container .ui.menu .item {
  text-align: center;
  line-height: 0;
}
.home-menu-container .ui.tabular.menu .item {
  border: none;
  height: 25px;
  min-width: 100px;
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
}
.discharged {
  color: var(--header-color);
  font-size: 16px;
  font-weight: 600;
}

.grid-container {
  height: 300px;
}
.m-top-s {
  margin-top: 10px;
}
// date picker
.select-period {
  position: 'relative' !important;
}
.input-date {
  position: absolute;
  top: 8;
  bottom: 4;
  left: 4;
  right: 5;
  height: 30px;
  width: 140px;
}
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.legendItemContainer {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.legend-logo {
  width: 2rem;
}

.legendColorItem {
  padding: 0.5rem;
  width: 3rem;
}

#rect10 {
  fill:#ffffff;
  fill-opacity:0.00746897;
  fill-rule:evenodd;
  stroke:#000000;
  stroke-width:8;
  stroke-miterlimit:4;
  stroke-dasharray:none;
  stroke-opacity:1;
}

#path841 {
  fill:#ffffff;
  fill-opacity:0.00746897;
  stroke:#000000;
  stroke-width:8;
  stroke-miterlimit:4;
  stroke-dasharray:none;
  stroke-opacity:1;
}

#path845 {
  fill:none;
  stroke:#000000;
  stroke-width:8;
  stroke-linecap:butt;
  stroke-linejoin:miter;
  stroke-miterlimit:4;
  stroke-dasharray:none;
  stroke-opacity:1;
}

#path847 {
  fill:none;
  stroke:#000000;
  stroke-width:8;
  stroke-linecap:butt;
  stroke-linejoin:miter;
  stroke-miterlimit:4;
  stroke-dasharray:none;
  stroke-opacity:1;
}

#rect867 {
  fill-opacity:1;
  stroke:none;
  stroke-width:7.06757;
  stroke-miterlimit:4;
  stroke-dasharray:none;
  stroke-opacity:1;
}

#gauge-value {
  font-size: 0.04em !important;
  font-weight: 600 !important;
}

.blah {
  color: #00c20a;
}
.header-container {
  background-color: var(--header-color);
  height: 46px;
  width: 100vw;
  .header-column1 {
    width: 20%;
  }
  .header-column2 {
    width: 60%;
  }
  .header-column3 {
    width: 20%;
  }
  .ui.tabular.menu .active.item {
    height: 34px;
  }
  a {
    font-size: 16px;
    padding: 0 2.2rem 0 2.2rem !important;
    margin-top: 1rem !important;
    margin-right: 0 !important;
  }
  h4 {
    color: var(--uielements-color) !important;
  }
}
.header-logo-positioning {
  margin: 5px 0px 5px 15px;
}
.header-logo-size {
  width: 42px;
  height: 38px;
}
.header-container .ui.menu {
  margin-top: 0px;
  height: 3rem;
  background-color: var(--header-color);
  border: none;
}
.header-container .ui.menu .item > i.dropdown.icon {
  margin: 0.5rem;
}
.header-container .ui.menu .item {
  padding: 0px;
  margin-right: 1rem;
}
.menu-wrapper {
  width: 30%;
  font-size: 2rem;
  margin-top: 1.5rem;
}
.menu-wrapper > li {
  color: var(--uielements-color);
  list-style-type: none;
}
.registration {
  color: var(--uielements-color);
  font-size: 16px;
  font-weight: 600;
}
.logo-container {
  width: 5%;
}
.registration-container {
  width: 95%;
  height: 100%;
}
.flex1-column-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

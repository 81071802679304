.admin-container {
  background-color: var(--background-color);
  height: 95%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  .ui.bottom.attached.segment {
    padding: 10px 5px 0px 0px !important;
    flex-grow: 1;
  }

  .ui.column {
    height: 100% !important;
  }

  .ui.segment {
    border: 0px !important;
  }
  .ui.tabular.menu .item {
    padding: 0;
    margin-right: 1rem;
  }
  .customer-detail-container {
    height: 80vh;
  }
  .customer-detail-container .admin-form {
    width: 90% !important;
  }
  .m-x-r {
    margin-right: 15px;
  }
  .ui.dropdown > .text {
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px;
  }
  i.icon:before {
    font-size: 20px;
    color: white;
  }
}
.arrow-icon {
  height: 8%;
  width: 8%;
}
.user-name {
  color: white;
  font-family: 'Nunito Sans';
  font-weight: 900;
  font-size: large;
  margin-right: 10px;
}
#admin-active,
#reports-active,
#vpp-active {
  border-radius: 1rem 1rem 0rem 0rem !important;
}
// #addNewUser,
// #allUser {
//   background-color: white !important;
//   pointer-events: none;
// }
.ui.selection.dropdown > .dropdown.icon {
  top: 0.1em !important;
}
.customer-details .ui.dropdown > .text,
.ui.dropdown:not(.button) > .default.text {
  position: absolute;
  top: 0.4em !important;
  color: var(--font-color) !important;
  font-size: 1rem !important;
  font-family: 'Nunito Sans';
}
.form-padding {
  padding: 20px;
}
.field :disabled {
  opacity: 0.8 !important;
}
#addAnotherUser {
  width: 150px;
}
#sentInvite {
  width: 100px;
}

.ui.menu > .item {
  font-weight: bold;
  font-size: 1.5rem;
}

.ui.menu > .item.active {
  font-weight: bold;
  font-size: 1.5rem;
  background-color: var(--background-color) !important;
}

.dashboard-container {
  height: 95% !important;
  max-width: 1800px;
  width: 95%;
  margin: auto;
  background-color: white;

  div.container {
    height: 100% !important;
  }
}

.flex1-grow {
  flex-grow: 1;
}

.ui.mini.input > input {
  margin: 0rem !important;
  height: 2rem !important;
  text-align: right;
  width: 10rem;
}

.mt1 {
  margin-top: 1rem;
}

div.listbox-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.2rem;

  div.ui.selection.dropdown {
    background-color: white !important;
    padding: 0.1rem;

    .text {
      padding: 0.2rem !important;
      top: 0rem !important;
      margin: 0.2rem !important;
      color: var(--font-color) !important;
    }

    i::before {
      color: var(--font-color) !important;
    }
  }
}

.VictoryContainer {
  svg {
    max-height: 100% !important;
    max-width: 1750px !important;
    margin: auto;
  }
  overflow: hidden !important;
}

#chart-axis-0-axisLabel-0 {
  transform: rotate(-90deg) translate(-73px, -54px) !important;
}

#overview_chart {
  overflow-y: auto;
}

.disabled.ui.selection.dropdown {
  background-color: lightgray;
  div.text {
    color: black !important;
  }
}

.users-container > .data-table {
  td > div {
    margin: auto;
  }

  thead {
    th:nth-child(2) {
      text-align: center;
    }
    th:nth-child(3) {
      text-align: center;
    }
  }

  tbody {
    td:nth-child(3) {
      text-align: center;
    }
  }
}

.dashboard-menu-container {
  height: 100% !important;

  .ui.bottom.attached.segment.active.tab {
    height: calc(100% - 26px) !important;
  }
}

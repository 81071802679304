.list-row .expand-border {
  border-top: 1px solid #bdbdbd;

  &:first-child {
    border-left: 1px solid #bdbdbd;
  }

  &:last-child {
    border-right: 1px solid #bdbdbd;
  }
}

.details-row .expand-border {
  border: 1px solid #bdbdbd;
  border-top: none;
}

.row-cell {
  padding: 10px;
  color: #4d4d4d;
}

.bottom-border {
  border-bottom: 1px solid #bdbdbd;
}

.cursor-pointer {
  cursor: pointer;
}

.border {
  border: 1px solid #bdbdbd;
}

.details-button {
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

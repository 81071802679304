@import '../../../sass/mixins';
.electric-car-outer {
  position: relative;
  display: inline-block;
}
.electric-car-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.assets-size {
  height: 70px;
}

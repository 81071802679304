.form-title {
  border-bottom: 1px solid black;
  text-align: left;
  font-weight: bolder;
  color: black;
}

.arrow-icon {
  height: 8%;
  width: 8%;
}
.user-name {
  color: white;
  font-family: 'Nunito Sans';
  font-weight: 900;
  font-size: large;
  margin-right: 10px;
}
#admin-active,
#reports-active,
#vpp-active {
  border-radius: 1rem 1rem 0rem 0rem !important;
}

.customer-detail-container {
  height: 75vh !important;
  overflow: hidden;
  width: fit-content;
}
.customer-detail-container .admin-form {
  width: 90% !important;
}

.ui.selection.dropdown > .dropdown.icon {
  top: 0.1em !important;
}
.customer-details .ui.dropdown > .text,
.ui.dropdown:not(.button) > .default.text {
  position: absolute;
  top: 0.4em !important;
  color: var(--font-color) !important;
  font-size: 1rem !important;
  font-family: 'Nunito Sans';
}
.customer-details .ui.dropdown > i.icon:before {
  color: var(--font-color);
  font-size: 14px;
}
.form-padding {
  padding: 20px;
}
.field :disabled {
  opacity: 0.8 !important;
}
#addAnotherUser {
  width: 150px;
}
#sentInvite {
  width: 100px;
}
#userUpdateForm,
#subscription {
  width: 90% !important;
  margin: auto;

  .field {
    display: flex !important;
    flex-grow: 1 !important;
  }

  .customer-detail-names {
    width: 10rem !important;
    flex-shrink: 0 !important;
  }

  .tiny {
    width: 4rem !important;
  }

  .larger {
    flex-grow: 1 !important;
  }

  .ml05 {
    margin-left: 0.5rem;
  }

  .hide-overflow {
    overflow: hidden !important;
    height: 3.25rem !important;
  }

  .submit-btn,
  .update-btn {
    color: var(--uielements-color);
    border-color: var(--uielements-color);
    background-color: white;
    border: 1px solid !important;
    border-radius: 5px;
    width: 80px;
    padding: 5px;
  }
}

.user-details-dropdown {
  font-weight: normal !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;

  .text {
    font-weight: normal !important;
    margin-top: 0% !important;
  }
}

.field > input:disabled.form1-item {
  background-color: var(--grey-color) !important;
  color: black !important;
}

#subscription .submit-btn {
  margin-top: 6.4rem !important;
}

#subscription .update-btn {
  margin-top: 2.5rem !important;
}

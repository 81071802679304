.electricity-graph-positioning {
  position: relative;
}
.electricity-symbol {
  position: absolute !important;
  height: 2rem;
  width: 100%;
  left: 0%;
  top: 20%;
  &.modified {
    top: 18%;
    left: -27.5%;
  }
}
.sun-symbol {
  position: absolute !important;
  height: 2rem;
  left: 47%;
  top: 23%;
  &.modified {
    left: 21%;
  }
}
.actual {
  color: var(--yellow-color);
  &.normal-tile {
    width: 56%;
  }
  &.modified-tile {
    width: 24%;
  }
}
.bold-font {
  font-size: 15px;
  font-weight: 900;
}
.m-x-left {
  margin-left: 10rem;
  margin-top: 1rem;
}
.m-x-left-expected {
  margin-left: 5rem;
  margin-top: 1rem;
}

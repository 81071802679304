.large-consumer-sm-container {
  height: 350px;
  width: 500px;
  background-color: white;
  border: 1px solid #07667c;
  margin: auto;
  .content-center {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .bold {
      font-size: 20px;
      font-weight: 700;
      line-height: 3;
    }
    .normal {
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
    }
    .try-again {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.configuration-details,
.large-cons-sm-container {
  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  .row-padding {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  .configuration-column1 {
    width: 12.5vw;
    text-align: left;
    margin-right: 10px;
  }
  .configuration-column2 {
    width: 16.66vw;
    text-align: left;
  }
  .configuration-column3 {
    width: 0.699vw;
    text-align: left;
  }
  .m-config-right {
    margin-right: 60px;
  }
  .m-config-left {
    margin-left: 10px;
  }
  input {
    background-color: var(--background-color);
    border: none;
    padding-left: 5%;
    font-family: 'Nunito Sans';
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 15.277vw;
    border-radius: 3px;
  }
  .smart-controller-btn {
    background: var(--uielements-color) !important;
    color: #ffff !important;
    padding: 0.5rem 0.5rem !important;
  }

  .required-field::after {
    content: '*';
    color: red;
    font-size: 2vh;
  }
  .obligation-input {
    background-color: #e0e0e0 !important;
    opacity: 0.9;
  }
  .ui.selection.dropdown {
    height: 3.16vh;
    width: 15.277vw;
  }
  .ui.dropdown > .text,
  .ui.dropdown:not(.button) > .default.text {
    position: absolute;
    top: 0.4em !important;
    color: var(--widgettitles-color) !important;
    font-size: 1rem !important;
    font-family: 'Nunito Sans';
  }
  .ui.disabled.dropdown,
  .ui.dropdown .menu > .disabled.item {
    background-color: var(--dark-grey-color) !important;
    opacity: 0.96 !important;
  }
  .ui.dropdown > .text::first-letter {
    text-transform: capitalize;
  }
  .scanner-size {
    height: 25px;
    width: 20px;
  }
  .scan-btn {
    margin-top: 0px !important;
  }
  /* Regarding tooltip */
  .tooltip {
    position: relative;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 28vw;
    height: auto;
    background-color: #ffffff;
    color: #555;
    border-width: 1px;
    border-style: solid;
    border-color: #4d4d4d;
    text-align: left;
    border-radius: 6px;
    font-size: 16px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: -430%;
    left: -3800%;
    margin-left: -5px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
.device-selected-detail {
  .ui.form {
    width: 100% !important;
    margin: 0% 0% !important;
  }
  .ui.form input {
    background-color: #ffff !important;
    border: 1px #bdbdbd solid !important;
  }
  .ui.form input[disabled] {
    opacity: 0.5;
    background-color: #bdbdbd  !important;
  }
  #unit-input {
    border-right: 0px #bdbdbd solid !important;
  }
}

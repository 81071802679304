@import '../../../sass/mixins';

.battery-outer {
  position: relative;
  display: inline-block;
}
.battery-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.battery-size {
  width: 250px;
}
.m-y-t-txt {
  margin-top: 2%;
}

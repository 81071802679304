.library_input {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.library_input-label_container {
    display: flex;
}

.library_input_item-dropdown {
    min-width: 45% !important;
    width: 45% !important;
}

.library_input_item-button {
    min-width: 4% !important;
    width: 4% !important;
}

.library_input_item {
    display: flex;
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
}
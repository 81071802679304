#users-list-community {
  overflow-y: scroll !important;
  max-height: calc(100% - 5rem) !important;

  .table > thead > tr > th:nth-child(2) {
    text-align: center !important;
  }

  .table > thead > tr > th:nth-child(3) {
    text-align: center !important;
  }

  td:nth-child(3) {
    text-align: center !important;
  }

  td:nth-child(2) {
    text-align: center !important;
  }
}

#users-list-admin {
  // overflow-y: scroll !important;
  max-height: calc(100% - 5rem) !important;

  .table > thead > tr > th:nth-child(3) {
    text-align: center !important;
  }

  .table > thead > tr > th:nth-child(4) {
    text-align: center !important;
  }

  td:nth-child(3) {
    text-align: center !important;
  }

  td:nth-child(4) {
    text-align: center !important;
  }
  .row-cell {
    padding-left:5px;
  }
}

#users-list-container {
  height: 100% !important;
}

#search-bar {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  margin: auto;
  background-color: white;
  width: 78%;
  margin-bottom: 0.5rem;
}

.users-list-field {
  width: 18rem !important;
}
.users-list-button {
  margin-left: 1rem !important;
  cursor: pointer !important;
  img {
    margin-bottom: 0.2rem;
  }
}

div.ui.dropdown.users-list-field {
  background-color: white !important;
  height: 2.5rem;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  div.text {
    color: black !important;
    font-weight: normal;
    margin-top: 0.7rem;
    font-size: medium;
  }

  .dropdown.icon {
    transform: translateY(0.5rem);
  }

  i:before {
    color: var(--font-color);
  }
}

div.ui.input.users-list-field {
  padding: 0px !important;
  height: 2.5rem;
  input {
    background-color: var(--background-color) !important;
    margin: 0px !important;
  }
}

.users-list-field:nth-child(n+2) {
  margin-left: 1rem;
}


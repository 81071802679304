#num_gateways {
  font-weight: bold;
  color: black !important;
}

.mtb2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mb2 {
  margin-bottom: 1rem;
}

.grid-col-2 {
  display: grid;
  grid-column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

.grid-col-4 {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.status-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

#community-selector {
  height: 2.5rem;
}

#status-content {
  flex-grow: 1;
}

#status-chart {
  padding: 1rem;
  display: flex;
  flex-direction: column;

  .react-datepicker-wrapper {
    overflow: auto;
  }

  .react-datepicker__input-container {
    border: solid lightgray 0.1px;
    border-radius: 3px;
    padding: 0.5rem;
    cursor: pointer;

    input {
      border: none !important;
      padding: 0.2rem;
      width: 12rem;
      cursor: pointer;
    }

    input:focus {
      outline: none;
    }

    i {
      width: 2rem;
      height: 2rem;
    }

    i.icon:before {
      color: black;
    }
  }

  .description-text {
    padding: 1rem;
    margin: 0px;
  }
}

#gateway-table {
  padding: 1rem;
  display: flex;
  flex-direction: column;

  table {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    text-align: center;

    thead {
      th {
        background-color: white !important;
        padding: 0.5rem;
      }

      th:nth-child(n + 3) {
        text-align: center;
      }

      .table-header {
        font-weight: bold;
      }
      .table-header-clickable {
        font-weight: bold;
        cursor: pointer !important;
      }
    }

    tbody {
      tr:nth-child(1) {
        td {
          border-top: 1px solid black !important;
          text-align: center;
        }
      }

      td {
        padding: 0.7rem 0.1rem 0.7rem 0.1rem;
      }
      td:nth-child(n + 3) {
        text-align: center;
      }
    }
  }

  div.gateway-table-footer {
    div.gateway-table-pagination {
      div.pagination-items {
        display: flex;
        flex-direction: row;

        input {
          width: 3em;
          height: 2.5rem;
          margin-right: 0.5em;
        }
      }
      div.pagination-btn-div {
        display: flex;
        flex-direction: row;

        i {
          width: 1em;
          margin-right: 0.5em;
          margin-left: 0.5em;
        }

        i.pagination-next::before {
          content: '\25b6';
          cursor: pointer;
        }

        i.pagination-back::before {
          content: '\25c0';
          cursor: pointer;
        }

        button {
          width: 1.5rem;
          box-sizing: border-box;
          margin: 0.2rem;
          background-color: white;
          outline: none;
          border: none;
          cursor: pointer;
          border: solid white 1px;
        }

        button:hover {
          border: solid var(--font-color) 1px;
          border-radius: 3px;
          background-color: lightgray;
        }

        button.active {
          border: solid var(--font-color) 1px;
          border-radius: 3px;
          background-color: lightgray;
        }
      }
    }
  }

  .gateway-table-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.content-center {
  justify-content: center;
  text-align: center;
}

#status_chart_element {
  margin-bottom: 1rem;
  margin-top: 0px;
  g:nth-child(1) > line {
    display: none;
  }
}

.legend-explainer {
  display: flex;
  flex-direction: row;
  justify-content: left;

  .legend-explainer-title {
    width: 11rem;
  }
}

div.spacer {
  width: 2em;
}

.sort-identifier {
  font-size: small;
  color: darkgray;
}

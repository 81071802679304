.report-workspace {
  background-color: white;
  font-family: 'Nunito-sans';

  .report-container {
    padding: 2rem;

    .report-topleft {
      display: flex;
      align-items: center;

      img {
        height: 50px;
      }
    }

    .report-content {
      font-size: 14px;
    }
    .align-euro-content {
      width: 65%;
      text-align: right;
    }
    .align-euro-content-column1 {
      width: 100%;
      text-align: right;
    }

    .align-euro-content-column2 {
      width: 55%;
      text-align: right;
    }
  }

  .report-search-form {
    margin-left: 0 !important;
  }
  .date-selection {
    color: var(--font-color);
    font-size: 14px;
  }
  .m-y-medium {
    margin-top: 15px;
  }
  .reporting-price {
    line-height: 200%;

    .reporting-price-bracket {
      // font-weight: 600;
      font-size: 200%;
    }
  }

  .ui.grid > .row > .column {
    display: flex;
    flex-direction: row;
  }

  .ui.grid > .row {
    padding: 0px 0 0 0;
  }

  .ui.table thead th {
    text-align: center;
  }
  .dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }

  .dot-yellow {
    background-color: #ffc000;
  }

  .dot-orange {
    background-color: #dc7570;
  }

  .m-y-t {
    margin-top: 35px;
  }

  .generate-report {
    width: '170px';
    height: '30px';
    padding-top: '5px';
    padding-bottom: '5px';
    background-color: var(--uielements-color);
    color: white;
  }
  .startDate,
  .endDate {
    width: 120px;
  }
}

#general-report-container {
  min-height: 200vh;
}

#month-report-container {
  min-height: 300vh;
}

#day-report-container {
  min-height: 300vh;
}

#year-report-container {
  min-height: 200vh;
}

.ui.tabular.menu .active.item {
  display: flex;
  justify-content: center;
}

.ui.divider:not(.vertical):not(.horizontal) {
  border-top: 1px solid grey;
  border-bottom: none;
}

.ui.active.dimmer {
  opacity: 0.5;
}

.pdf-page {
  padding: 20px;
}
.Loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

/* loading symbol rotates */
.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@media screen {
  .graph-container {
    display: flex !important;
    flex-direction: column !important;
  }
}

.container {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 90vh !important;

  .container__inner {
    width: 40%;
    height: 90%;
    background-color: white;
    overflow: auto;
    margin-left: 15px;

    hr {
      width: 94%;
      margin: 0px 3%;
      border-top: 2px solid #8d99ae;
    }

    .ui.form {
      font-size: 1.2rem;
      width: 100% !important;
      margin: 0 !important;
    }
  }

  .ui.form input {
    background-color: var(--background-color) !important;
    border: none !important;
    padding-left: 5% !important;
    font-family: 'Nunito Sans' !important;
    font-size: 14px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    width: 380px;
    height: 31px;
  }

  h3 {
    font-size: 18px;
    // margin-left: 0px !important;
    color: #4d4d4d !important;
    font-weight: 600;
    // font-family: 'Nunito Sans';
  }
}

.container__inner>*:first-child {
  margin: 20px 0px 10px 0px;
  padding-left: 5%;
}

.form2-item {
  width: 100% !important;
  margin-bottom: 5px !important;
}

.form-section {
  width: 35%;
  margin-left: 20px;
  margin-bottom: 0.7rem;
  // color: black;
  font-weight: bolder;
  // text-align: left;
  // font-size: medium;
  // margin-top: 15px;
  // margin-bottom: 15px;
}

.ui.selection.dropdown {
  background-color: var(--background-color);
  min-height: 0;
}

.device_list_container {
  height: 100% !important;
  overflow: scroll;
}
.solar-legend {
  height: 1rem;
  width: 3rem;
  background-color: #fbbb21;
  margin-right: 2rem;
}
.grid-legend {
  height: 1rem;
  width: 3rem;
  background-color: rgba(126, 126, 126);
  margin-right: 2rem;
}
.home-battery-legend {
  background-color: rgba(38, 134, 159);
  height: 1rem;
  width: 3rem;
  margin-right: 2rem;
}
.legend-p-y {
  margin: 0rem 1rem 1rem 0rem;
}
.m-p-y-top {
  margin-top: 2rem;
}
.legend-p-x {
  margin-right: 3rem;
}

.register-container .ui.bottom.attached.segment.active.tab {
  height: 100%;
  background-color: var(--background-color);
  font-weight: bolder;
}
#clientdetails {
  background-color: white !important;
  // margin-left: 48rem !important;
  font-size: 16px;
  width: 55%;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.register-container .ui.tabular.menu .active.item {
  color: var(--font-color) !important;
  padding: 5px;
  height: 22px;
  width: 120px;
  margin-top: 18px;
  text-align: center;
  border: none;
  font-size: 16px;
  font-weight: bolder;
  color: var(--font-color);
}
.register-container .ui.segment {
  border: none !important;
}
.register-container .ui.form .field {
  margin: 0 0 0rem;
}
.register-container {
  min-height: 185vh;
  display: flex;
  flex-direction: column;
}
/* margins and paddings goes here */
.m-py-top-usersettings {
  margin-top: 8rem;
}
.m-py-top-subscription {
  margin-top: 8.5rem;
}
.m-py-top-building {
  margin-top: 9rem;
}
.m-py-top-customer {
  margin-top: 0.1rem;
}
.m-py-top-customerprivate {
  margin-top: 3rem;
}
.m-px-application {
  margin-left: 4%;
  margin-bottom: 2%;
}
.ui.form select {
  padding: 0.2rem 1rem !important;
  color: var(--font-color);
  font-family: 'Nunito Sans';
  font-weight: bolder;
}
#company-container {
  height: 100% !important;
  width: 98%;
  margin-left: 1%;
}
.company-form {
  width: 95%;
  height: 100%;
  background-color: white;
}
.ui.small.modal {
  width: 350px !important;
  margin: 0;
}
.user-detail-container .header-logo-positioning {
  margin-right: 20rem;
}
.user-detail-container .header-container {
  background-color: var(--header-color) !important;
}
.user-detail-container .header-container a {
  text-align: center;
  margin-top: 1rem !important;
  margin-right: 0 !important;
  height: 34px;
  width: 150px;
}
.user-detail-container .header-container strong {
  color: var(--uielements-color) !important;
  font-size: 16px;
  font-family: 700;
}
#home-active-user,
#reports-active-user,
#vpp-active-user,
#optimalisatie-active-user {
  border-radius: 0.5rem 0.5rem 0rem 0rem !important;
  font-size: 16px;
  font-weight: 700;
  margin: 1rem 1rem 0rem 1rem !important;
}
#vpp-active-user,
#optimalisatie-active-user {
  pointer-events: none;
}
#home-active-user {
  padding: 0rem 3.5rem 0rem 3.5rem !important;
}
#reports-active-user,
#optimalisatie-active-user {
  padding: 0rem 1.5rem 0rem 1.5rem !important;
}
#smart-meter-date {
  font-size: 14px;
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  pointer-events:none;
}

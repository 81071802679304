.input-label {
    width: 35%;
    margin-left: 20px;
    margin-bottom: 0.7rem;
}

.input-item {
    width: 100% !important;
    margin-bottom: 5px !important;
}

.required-field::after {
    content: '*';
    color: red;
    font-size: 2vh;
}
.login-container {
  margin: 0;
  height: 100vh;
  background: url('/assets/Lyv_background.png') transparent;
  background-size: cover;
  width: 100%;
}

.login-container .ui.form {
  width: 248px !important;
  margin: 0% 0% 0% 12% !important;
}
.login-form-inner-item {
  box-sizing: border-box;
  width: 100%;
  margin: 2% 0%;
}
.login-margin-top {
  margin-top: 7rem;
}
.login-form-inner-item > * {
  width: 80%;
}

.login-form-inputdetails {
  margin-top: 7%;
  margin-bottom: 7%;
  width: 100%;
}

.login-wachtword-verget-link {
  color: black;
  text-decoration: underline;
  cursor: pointer;
  font-size: small !important;
  text-align: left;
  margin-top: 2rem;
}

.login-font-blue {
  color: var(--uielements-color);
  font-size: 1rem;
}
.login-btn {
  background: #27ae60 !important;
  font-family: 'Nunito Sans' !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: white !important;
  padding: 4.5% 5% 4.5% !important;
  border-radius: 3px !important;
  height: 39px;
  margin-bottom: 50px !important;
}

.login-onthoudmetxt {
  font-size: small;
  text-decoration: underline;
  text-shadow: 0px 5px 5px grey;
}
.onhoud-contianer {
  height: 10%;
}
.login-container .form-container {
  width: 325px;
  min-height: 411px;
  background-color: white;
}
.ui.modal > .close {
  top: -0.5rem !important;
  right: -0.8rem !important;
  color: grey !important;
}
.ui.modal {
  font-size: 1rem;
  width: 600px !important;
}
.forgetpassword-inputform {
  width: 560px !important;
}
.forgetpassword-container {
  width: 300px !important;
}
.forgetpassword-container .ui.input {
  margin-bottom: 20px;
}
.forgetpassword-container .ui.button {
  margin-bottom: 20px;
}
.forgetpassword-container .ui.input > input {
  background: var(--background-color) !important;
}
.ui.modal > .actions {
  text-align: right !important;
}
.ui.modal > .content {
  text-align: center;
}
/* Placeholder text for input text */
input[name='userName']::-webkit-input-placeholder,
input[name='password']::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #8f8f8f !important;
  font-size: 14px !important;
  font-weight: 400;
  font-weight: Nunito Sans;
}
input[name='userName']::-moz-placeholder,
input[name='password']::-moz-placeholder {
  /* Firefox 19+ */
  color: #8f8f8f !important;
  font-size: 14px !important;
  font-weight: 400;
  font-weight: Nunito Sans;
}
input[name='userName']:-ms-input-placeholder,
input[name='password']::-ms-input-placeholder {
  /* IE 10+ */
  color: #8f8f8f !important;
  font-size: 14px !important;
  font-weight: 400;
  font-weight: Nunito Sans;
}
input[name='userName']:-moz-placeholder,
input[name='password']:-moz-placeholder {
  /* Firefox 18- */
  color: #8f8f8f !important;
  font-size: 14px !important;
  font-weight: 400;
  font-weight: Nunito Sans;
}


.main_meter_application_menu {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow: auto;
    width: 100%;
    font-size: 2em;
}

.main_meter_library_option_item {
    display: flex;
    padding: 20px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    border: 1px solid gray;
    cursor: pointer;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 2px 2px 2px 3px #ccc;
}

.main_meter_library_option_item-make {
    font-weight: 400;
    font-size: 1em;
}

.main_meter_library_option_item-model {
    font-size: 1em;
}

.main_meter_library_option_item-application {
    font-size: 1.3em;
    font-weight: bold;
}

.main_meter_application_menu-library_item {
    margin-top: 5px;
    margin-bottom: 5px;
}

.main_meter_application_menu-library_item-selected {
    border: 2px solid green;
    box-shadow: 3px 3px 5px 4px #ccc;
}